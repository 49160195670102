@import "@Static/css/common";

// theme colours
$color1: getColorValue(1, (255, 91, 0));

.container {
    margin-left: 20px;
    position: relative;
    &:hover {
        .dropdownContainer {
            display: block;
        }
    }
}

.button {
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
}

.accountIcon {
    display: flex;
    border-radius: 50%;
}

.dropdownContainer {
    position: absolute;
    top: 31px;
    left: -260%;
    display: none;
    padding-top: 10px;
    &:hover {
        display: block;
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
}

.dropdownItem {
    padding: 8px 15px;
    transition: 0.1s ease-in all;
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: 0.85px;
    font-size: 0.7rem;
    text-decoration: none !important;
    color: $color1;
    &:hover {
        background-color: $color1;
        color: #fff;
        opacity: 0.6;
    }
    &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
