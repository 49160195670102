@import "@Static/css/common";

.root {
    position: relative;
}

.container {
    top: 1.5rem;
    right: 0;
    position: absolute;
    background-color: white;
    color: #2e384d;
    border-radius: 5px;
    cursor: default;
    box-shadow: 0px 3px 6px #00000014;
    border-bottom: 2px solid #e4e8f0;

    &.yoga,
    &.growth,
    &.eduverse {
        width: min(500px, calc(100vw - 2rem));
    }

    &.modern,
    &.classic,
    &.unfold,
    &.linktemp {
        width: min(500px, calc(100vw - 5rem));
    }

    &.elementary {
        width: min(500px, calc(100vw - 4.5rem));
    }

    &.unlock {
        width: min(500px, calc(100vw - 2rem));
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.uniformVerticalPadding {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.uniformSmallVerticalPadding {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.uniformHorizontalPadding {
    padding-left: 1rem;
    padding-right: 1rem;
}

.plainTextButton {
    text-decoration: none;
    padding: 0;
}

.closeButton {
    color: #2e384d;
}

.notificationsContainer {
    max-height: calc(60vh - 2rem);
    overflow-y: auto;
    @extend .plainScrollbar;
}

.notificationContainer {
    background-color: #f6fafc;
    border-top: 2px solid #e4e8f0;
}

.notificationMessageContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.notificationMessage {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notificationReminderContainer {
    font-weight: bold;
    cursor: pointer;
}

.notificationReminder {
    white-space: break-spaces;
}

.emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 100%;
}

.loadMoreContainer {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}
