.container {
    display: flex;
    cursor: pointer;
    margin-left: 20px;
}

.newNotificationIconContainer {
    position: relative;
}

.newNotificationIcon {
    position: absolute;
    left: -28px;
    top: -22px;
    color: #14be0d;
}
