.expandY {
    transform-origin: top;
    transition: 0.2s ease-in-out all;
    &.open {
        filter: opacity(1);
        transform: scaleY(1);
    }
    &:not(.open) {
        height: 0;
        filter: opacity(0);
        transform: scaleY(0);
    }
}
