@import "@Static/css/common";
@import "@Static/css/effects";

$bgColor: var(
    --template-header-background-host-theme,
    var(--template-primary-color-host-theme, 0, 0, 0)
);
$textColor: var(--template-header-text-host-theme, 255, 255, 255);

.eduverseArrow {
    position: relative;
    left: 65px;
    width: fit-content;
    transform: rotate(22deg);
    padding: 18px;
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.root {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 30;
    box-shadow: 1px 1px 3px 1px #0004;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;

    background-color: rgba($bgColor, 0.9);
    backdrop-filter: saturate(180%) blur(20px);
    color: rgb($textColor);
}

.navButton {
    cursor: pointer;
    color: rgb($bgColor);
    background-color: rgb($textColor) !important;
    padding: 5px 13px 5px 13px;
    border-radius: 5px;
    margin-right: 15px;
}
.navbarItem {
    color: rgb($textColor);
    text-decoration: none !important;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.1s ease all;
    &:not(.button):hover {
        transform: scale(1.05);
    }
}
.navbarLogo {
    font-size: 26px;
    color: rgb($textColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;
}

.button {
    cursor: pointer;
    background-color: rgb($textColor);
    color: rgb($bgColor);
}

.homeButton {
    display: flex;
    align-items: center;
    margin: 0;
}

.rightContainer {
    display: flex;
    align-items: center;
}
.eduverseArrowContainer {
    flex-grow: 1;
}

@media only screen and (max-width: 1024px) {
    .desktop {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .mobile {
        display: none;
    }
}

@media (max-width: 600px) {
    .navButton {
        color: rgb($textColor) !important;
        background-color: rgb($bgColor) !important;
    }
    .navbarLogo {
        font-size: 20px;
    }
}

.desktop {
    .button {
        border-radius: 4px;
        padding: 6px 12px;
        border: 1px solid transparent;
        transition: 0.2s ease all;
        &:hover {
            background-color: rgb($bgColor);
            border: 1px solid rgb($textColor);
            color: rgb($textColor);
        }
    }

    .eventListingsButton {
        position: relative;
        &:hover,
        &:active {
            .eventListingsDropdownContainer {
                display: flex;
            }
        }
    }

    .eventListingsDropdownContainer {
        border-radius: 4px;
        position: absolute;
        top: calc(100% - 5px);
        left: calc(-50% - 10px);
        margin-top: 5px;
        display: none;
        width: 15vw;
        flex-direction: column;
        background-color: rgb($bgColor);
        padding: 10px 0;
    }

    .eventListingsDropdownItem {
        padding: 5px 20px;
        margin: 0;
        &:hover,
        &:active {
            color: rgb($bgColor);
            background-color: rgb($textColor);
        }
    }
}

.mobileRoot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 100%);
    padding: 8px 12px;
    background-color: rgb($bgColor);
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2) inset;
    user-select: none;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    @extend .expandY;

    overflow-y: auto;
    @extend .themedScrollbar;
}

.mobile {
    .mobHamMenuButton {
        display: flex;
        padding: 2px 8px;
        margin: 10px;
        font-size: 18px;
        color: rgb($bgColor);
        background-color: rgb($textColor);
        border-radius: 4px;
        font-weight: 700;
        cursor: pointer;
        display: block;
        height: 26px;
    }
    .navButton {
        color: rgb($bgColor) !important;
        background-color: rgb($textColor) !important;
    }
    .navbarItem {
        padding: 2px 0;
        margin: 2px 20px;
        &:not(.button):hover {
            transform: translateX(4px);
        }
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        margin: 10px;
        border-radius: 6px;
    }

    hr {
        margin: 10px 20px;
    }

    .eventListingsButton {
        display: flex;
        align-items: center;
    }

    .eventListingsDropdownContainer {
        flex-direction: column;
        &.open {
            display: flex;
        }
        &:not(.open) {
            display: none;
        }
    }
}
